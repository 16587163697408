import {
  FetchItemsFilterItems,
  FiltersPopoverTypesEnum
} from '../../../../../../types';

import { useIndexPageFiltersPopover } from '../../../../../common/hooks/useIndexPageFiltersPopover';
import { useSearchFilters } from '../../../../hooks/useSearchFilters';

import {
  FETCH_SEARCH_FILTERS_QUERY,
  FetchSearchFiltersQueryResponse
} from '../../../../queries/fetchSearchFilters.query';

import { SearchFilterCache } from '../../../../SearchFilterCache';
import { SearchFiltersScopes } from '../../../../searchFiltersConstants';

interface SearchFiltersPopoverOptions<FiltersType> {
  onFiltersChange?: FetchItemsFilterItems<FiltersType>;
  onClosePopover?: () => void;
  popoverTargetId?: string;
  scope: SearchFiltersScopes[];
}

function useSearchFiltersPopover<FiltersType>({
  onFiltersChange,
  onClosePopover,
  popoverTargetId,
  scope
}: SearchFiltersPopoverOptions<FiltersType>) {
  const {
    searchFilters,
    searchFiltersFetched,
    searchFiltersLoading,
    searchFiltersError
  } = useSearchFilters<FetchSearchFiltersQueryResponse<FiltersType>>({
    cacheKey: SearchFilterCache.indexCacheKey(),
    query: FETCH_SEARCH_FILTERS_QUERY,
    initialFilters: { scope: { in: scope }, self: true }
  });

  const {
    popoverDivRef,
    handleClosePopover,
    handleFiltersChange,
    handleToggleIsPopoverFixed
  } = useIndexPageFiltersPopover<FiltersType>({
    popoverType: FiltersPopoverTypesEnum.SAVED_FILTERS,
    onFiltersChange,
    onClosePopover,
    targetId: popoverTargetId
  });

  return {
    searchFilters,
    searchFiltersFetched,
    searchFiltersLoading,
    searchFiltersError,
    popoverDivRef,
    handleClosePopover,
    handleFiltersChange,
    handleToggleIsPopoverFixed
  };
}

export default useSearchFiltersPopover;

import {
  INITIAL_SEARCH_FILTERS_FILTERS,
  INITIAL_SEARCH_FILTERS_LIMIT,
  INITIAL_SEARCH_FILTERS_PAGE,
  INITIAL_SEARCH_FILTERS_SORT
} from '../../searchFiltersConstants';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  FetchSearchFiltersCacheKey,
  FetchSearchFiltersFilters,
  FetchSearchFiltersGqlQuery,
  FetchSearchFiltersLimit,
  FetchSearchFiltersPage,
  FetchSearchFiltersSort
} from '../../searchFiltersTypes';

import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

interface SearchFiltersDefaultOptions {
  cacheKey: FetchSearchFiltersCacheKey;
  query: FetchSearchFiltersGqlQuery;
  initialFilters?: FetchSearchFiltersFilters;
  initialSort?: FetchSearchFiltersSort;
  initialPage?: FetchSearchFiltersPage;
  initialLimit?: FetchSearchFiltersLimit;
  options?: {
    cacheTime?: number;
  };
}

interface SearchFiltersWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface SearchFiltersWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type SearchFiltersOptions = SearchFiltersDefaultOptions &
  (
    | SearchFiltersWithPrefetchItemOptions
    | SearchFiltersWithoutPrefetchItemOptions
  );

const scope = 'searchFilters';

function useSearchFilters<ItemType>({
  cacheKey,
  query,
  initialFilters = INITIAL_SEARCH_FILTERS_FILTERS,
  initialSort = INITIAL_SEARCH_FILTERS_SORT,
  initialPage = INITIAL_SEARCH_FILTERS_PAGE,
  initialLimit = INITIAL_SEARCH_FILTERS_LIMIT,
  fetchItemCacheKey,
  fetchItemQuery,
  options = {}
}: SearchFiltersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    fetchItemCacheKey,
    fetchItemQuery,
    options
  });

  return {
    searchFiltersData: data,
    searchFilters: items,
    searchFiltersError: itemsError,
    searchFiltersTotalCount: itemsTotalCount,
    searchFiltersFetched: isFetched,
    searchFiltersLoading: isLoading,
    searchFiltersIsPlaceholderData: isPlaceholderData,
    searchFiltersFilters: currentFilters,
    searchFiltersSort: currentSort,
    searchFiltersPage: currentPage,
    searchFiltersLimit: currentLimit,
    clearSearchFiltersFilters: clearItemsFilters,
    filterSearchFilters: filterItems,
    limitSearchFilters: limitItems,
    paginateSearchFilters: paginateItems,
    prefetchSearchFilter: prefetchItem,
    prefetchSearchFilters: prefetchItems,
    sortSearchFilters: sortItems
  };
}

export default useSearchFilters;

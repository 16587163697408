import { gql } from 'graphql-request';

import {
  SearchFilterColors,
  SearchFilterCreatedAt,
  SearchFilterID,
  SearchFilterName,
  SearchFilterNanoID,
  SearchFilterUpdatedAt,
  SearchFilterUUID
} from '../searchFiltersTypes';

import { SearchFiltersScopes } from '../searchFiltersConstants';

export type FetchSearchFiltersQueryResponse<FiltersType> = {
  createdAt: SearchFilterCreatedAt;
  colors: SearchFilterColors;
  data: FiltersType;
  id: SearchFilterID;
  name: SearchFilterName;
  updatedAt: SearchFilterUpdatedAt;
  uuid: SearchFilterUUID;
  nanoId: SearchFilterNanoID;
  scope: SearchFiltersScopes;
};

export const FETCH_SEARCH_FILTERS_QUERY = gql`
  query SearchFilters(
    $filters: SearchFiltersFilters
    $sort: [SearchFiltersSortEnum!]
    $offset: Int
    $limit: Int
  ) {
    searchFilters(
      filters: $filters
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      nodes {
        id
        uuid
        nanoId
        name
        colors
        data
        scope
        createdAt
        updatedAt
      }
      paginationInfo {
        totalCount
      }
    }
  }
`;

import React, { memo, useCallback, ForwardedRef } from 'react';
import trim from 'lodash/trim';

import { ClassName } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { useTranslate } from '../../../common/hooks/useTranslate';
import { FiltersProps, useFilters } from '../hooks/useFilters';

import { Icon } from '../../Icon';

interface TextFilterProps extends FiltersProps<string> {
  inputRef?: ForwardedRef<HTMLInputElement>;
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  i18nPlaceholder?: string;
  id?: string;
  inputClassName?: string;
  onFocus?: () => void;
  placeholder?: string;
  trimmedChars?: string;
  type?: string;
  iconClassName?: ClassName;
  iconWrapperClassName?: ClassName;
}

function TextFilter({
  inputRef,
  autoComplete = 'off',
  className,
  disabled,
  i18nPlaceholder,
  id,
  inputClassName,
  name,
  onChange,
  onFocus,
  placeholder,
  trimmedChars,
  type,
  value = '',
  iconClassName,
  iconWrapperClassName
}: TextFilterProps) {
  const { t } = useTranslate();

  const { updatedValue, handleChange } = useFilters<string>({
    name,
    value,
    emptyValue: '',
    onChange,
    debounced: true
  });

  const handleInputChange = useCallback(
    ({ target: { value: inputValue } }) => {
      handleChange(trimmedChars ? trim(inputValue, trimmedChars) : inputValue);
    },
    [trimmedChars, handleChange]
  );

  return (
    <div className={className}>
      <div
        className={
          iconWrapperClassName ||
          'absolute flex inset-y-0 items-center left-0 ml-3 pointer-events-none'
        }
      >
        <Icon icon={IconsEnum.SEARCH} className={iconClassName} />
      </div>
      <input
        ref={inputRef}
        autoComplete={autoComplete}
        id={id}
        name={name}
        value={updatedValue}
        className={inputClassName}
        disabled={disabled}
        placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
        type={type}
        onChange={handleInputChange}
        onFocus={onFocus}
      />
    </div>
  );
}

export default memo(TextFilter);
